import React, { useState } from 'react'
import Navbar from '../Navbar'
import usePersistedState from 'use-persisted-state-hook'
import { addCalendar, addOrders } from '../../firebase/apis'

function Confirm() {

    const [quiz] = usePersistedState('thisQuiz')
    const [cart] = usePersistedState('thisCart')

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [address, setAddress] = useState("")
    const [price, setprice] = useState(0)

    async function checkout(e) {
        e.preventDefault()

        const outputArray = []
        for (const key in cart) {
            if (cart[key] !== "" && cart[key] != "Time_Slot" && cart[key] != "date" && cart[key] != "estimated_time" && cart[key] != "price" && cart[key] != "q") {
                outputArray.push({ ques: key, q: cart[key] });
            }
        }

        const payload = {
            paymentMethod: "none",
            quiz,
            date: cart.date,
            time: cart.Time_Slot,
            product: JSON.stringify([outputArray]),
            name,
            email,
            phone,
            city,
            address,
            orderID: "",
            price: cart.price,
            paymentType: "hourly"
        }

        await addOrders(payload)
        await addCalendar({
            date: cart.date,
            Time_Slot: cart.Time_Slot
        })
        window.location.href = window.location.origin

    }



    return (
        <>

            <Navbar />

            <section className="confirm-section">
                <div className="container">
                    <div className="row d-flex jc-center ai-center">
                        <div className="col">
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            {/* <h5 className="mb-3">
                                                <a href="#!" className="text-body">
                                                    <i className="fas fa-long-arrow-alt-left me-2"></i>
                                                    Continue shopping
                                                </a>
                                            </h5> */}

                                            <div className='confirm'>
                                                <div className='estimated_work'>
                                                    <h3>Estimated scope of work: <span> {cart?.estimated_time} </span></h3>
                                                    <h6>Hourly rate ${cart?.price}/hr</h6>
                                                    <p>The final quote will be generated by our vetted handyman after they discuss with you.</p>
                                                </div>

                                                <div className="summary">
                                                    <h5>Appointment Summary</h5>

                                                    <div className='summary_body'>
                                                        <div className="service_detail">
                                                            <div className='d-flex jc-between ai-center'>
                                                                <div className="s_name">
                                                                    <h6>Handyman Services</h6>
                                                                </div>
                                                                <div className="s_charges">
                                                                    <p>${cart?.price}/hr</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex jc-between ai-center'>
                                                                <div className="s_name">
                                                                    <h6>Security & support fee</h6>
                                                                </div>
                                                                <div className="s_charges">
                                                                    <p>$14.90</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="summary_detail">
                                                            <div className="estimated_time d-flex jc-between ai-center">
                                                                <h6>Estimated</h6>
                                                                <p>{cart?.estimated_time}</p>
                                                            </div>

                                                            <div className="estimated_time d-flex jc-between ai-center">
                                                                <h6>Requested Time</h6>
                                                                <p>{cart?.date}, {cart?.Time_Slot}</p>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    {/* <div className="confirm_btn">
                                                        <button className='btn'>Confirm and Book</button>
                                                    </div> */}
                                                </div>
                                            </div>


                                        </div>

                                        <div className="col-lg-6">
                                            <div className="card cart-detail text-white" style={{ height: "100%" }}>
                                                <div className="card-body">
                                                    <div className="d-flex jc-between ai-center mb-4">
                                                        <h5 className="mb-0">User Details</h5>
                                                        {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp" className="img-fluid rounded-3" style={{ width: "45px" }} alt="Avatar" /> */}
                                                    </div>

                                                    <form className="mt-4" onSubmit={(e) => checkout(e)}>
                                                        <div className="form-outline form-white mb-4">
                                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control form-control-lg" placeholder="Enter Fullname" required />
                                                        </div>

                                                        <div className="form-outline form-white mb-4">
                                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" placeholder="Enter Email" required />
                                                        </div>

                                                        <div className="form-outline form-white mb-4">
                                                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control form-control-lg" placeholder="Enter Contact Number" required />
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6 mb-4">
                                                                <div className="form-outline form-white">
                                                                    <input type="text" value={city} onChange={(e) => setCity(e.target.value)} className="form-control form-control-lg" placeholder="Enter City" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-4">
                                                                <div className="form-outline form-white">
                                                                    <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control form-control-lg" placeholder="Enter Address" required />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr className="my-4" />

                                                        <button type="submit" className="btn checkout-btn btn-block btn-lg">
                                                            <div className="d-flex jc-center">
                                                                <span>Place Order <i className="fas fa-long-arrow-alt-right ms-2"></i></span>
                                                            </div>
                                                        </button>
                                                    </form>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default Confirm
